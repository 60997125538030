
import { defineComponent } from 'vue'
import ArrowPagination from '@/assets/svg/arrow-pagination.svg?inline'

export default defineComponent({
  name: 'Pagination',
  data () {
    return {}
  },
  props: {
    propertyMeta: {
      type: Object,
      default: () => {
        return {}
      }
    },
    propertyList: {
      type: Object,
      default: () => {
        return {}
      }
    },
    handlePage: {
      type: Function,
      default: () => {
        return false
      }
    },
    page: {
      type: Number,
      default: 1
    }
  },
  components: {
    ArrowPagination
  }
})
